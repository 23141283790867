import cloneDeep from 'lodash/cloneDeep';
import Store from '@/store';
import TagService from '../services/tag.service';
import Tags from '../views/Tags';
import ColorInput from '@/components/ColorInput';
import CategorySelector from '../components/CategorySelector.vue';

export default (context, router) => {
    context.addRedirectPath({ path: '/company/tags/', withID: false });

    const tagsContext = cloneDeep(context);
    tagsContext.routerPrefix = 'company/tags';

    const crud = tagsContext.createCrud('tags.crud-title', 'tags', TagService);
    const crudEditRoute = crud.edit.getEditRouteName();
    const crudNewRoute = crud.new.getNewRouteName();

    const navigation = { edit: crudEditRoute, new: crudNewRoute };

    crud.new.addToMetaProperties('permissions', 'tags/create', crud.new.getRouterConfig());
    crud.new.addToMetaProperties('navigation', navigation, crud.new.getRouterConfig());
    crud.new.addToMetaProperties('afterSubmitCallback', () => router.go(-1), crud.new.getRouterConfig());

    crud.edit.addToMetaProperties('permissions', 'tags/edit', crud.edit.getRouterConfig());

    const grid = tagsContext.createGrid('tags.grid-title', 'tags', TagService);
    grid.addToMetaProperties('navigation', navigation, grid.getRouterConfig());
    grid.addToMetaProperties(
        'permissions',
        () => Store.getters['user/user'].roles_access.is_admin === true,
        grid.getRouterConfig(),
    );

    const fieldsToFill = [
        {
            key: 'id',
            displayable: false,
        },
        {
            label: 'field.name',
            key: 'name',
            type: 'input',
            required: true,
            placeholder: 'field.name',
        },
        {
            label: 'field.category',
            key: 'category',
            required: true,
            render: (h, data) => {
                return h(CategorySelector, {
                    props: {
                        value: data.currentValue,
                    },
                    on: {
                        change(value) {
                            data.inputHandler(value);
                        },
                    },
                });
            },
        },
        {
            label: 'field.color',
            key: 'color',
            required: false,
            render: (h, data) => {
                const randomColor = `#${Math.floor(Math.random() * 16777215).toString(16)}`;
                return h(ColorInput, {
                    props: {
                        value: typeof data.currentValue === 'string' ? data.currentValue : randomColor,
                    },
                    on: {
                        change(value) {
                            data.inputHandler(value);
                        },
                    },
                });
            },
        },
    ];

    crud.edit.addField(fieldsToFill);
    crud.new.addField(fieldsToFill);

    grid.addColumn([
        {
            title: 'field.name',
            key: 'name',
            render(h, { item }) {
                return h('span', item.attributes.name);
            },
        },
        {
            title: 'field.color',
            key: 'color',
            render(h, { item }) {
                return h(
                    'span',
                    {
                        style: {
                            display: 'flex',
                            alignItems: 'center',
                        },
                    },
                    [
                        h('span', {
                            style: {
                                display: 'inline-block',
                                background: item.attributes.color,
                                borderRadius: '4px',
                                width: '16px',
                                height: '16px',
                                margin: '0 4px 0 0',
                            },
                        }),
                        h('span', {}, [item.attributes.color]),
                    ],
                );
            },
        },
    ]);

    grid.addAction([
        {
            title: 'control.edit',
            icon: 'icon-edit',
            onClick: (router, { item }, context) => {
                context.onEdit(item);
            },
            renderCondition: ({ $can }, item) => {
                return $can('update', 'status', item);
            },
        },
        {
            title: 'control.delete',
            actionType: 'error',
            icon: 'icon-trash-2',
            onClick: async (router, { item }, context) => {
                context.onDelete(item);
            },
            renderCondition: ({ $can }, item) => {
                return $can('delete', 'status', item);
            },
        },
    ]);

    grid.addPageControls([
        {
            label: 'control.create',
            type: 'primary',
            icon: 'icon-edit',
            onClick: ({ $router }) => {
                $router.push({ name: crudNewRoute });
            },
        },
    ]);

    return {
        accessCheck: async () => Store.getters['user/user'].roles_access.is_admin,
        scope: 'company',
        order: 22,
        component: Tags,
        route: {
            name: 'Tags.crud.tags',
            path: '/company/tags',
            meta: {
                label: 'tags.grid-title',
                service: new TagService(),
            },
            children: [
                {
                    ...grid.getRouterConfig(),
                    path: '',
                },
                ...crud.getRouterConfig(),
            ],
        },
    };
};
