export default class TaskPolicy {
    static create(user) {
        return user.roles_access.is_admin;
        // this.reporterMaintainer(user) ||
        // this.reporterMember(user) ||
        // this.managerOwner(user) ||
        // this.managerMaintainer(user) ||
        // this.managerMember(user) ||
        // this.clientOwner(user) ||
        // this.clientMaintainer(user) ||
        // this.employeeMaintainer(user)
    }

    static update(user, _model) {
        return user.roles_access.is_admin;
        // this.reporterMaintainer(user) ||
        // this.reporterMember(user) ||
        // this.managerOwner(user) ||
        // this.managerMaintainer(user) ||
        // this.managerMember(user) ||
        // this.clientOwner(user) ||
        // this.clientMaintainer(user) ||
        // this.employeeMaintainer(user) ||
        // this.clientMember(user) ||
        // this.employeeMember(user)
    }

    static delete(user, _model) {
        return user.roles_access.is_admin;
        // this.reporterMaintainer(user) ||
        // this.reporterMember(user) ||
        // this.managerOwner(user) ||
        // this.managerMaintainer(user) ||
        // this.managerMember(user) ||
        // this.clientOwner(user) ||
        // this.clientMaintainer(user) ||
        // this.employeeMaintainer(user)
    }

    // static reporterMaintainer(user) {
    //     return user.roles_access.is_reporter && user.roles_access.is_maintainer;
    // }

    // static reporterMember(user) {
    //     return user.roles_access.is_reporter && user.roles_access.is_member;
    // }

    // static managerOwner(user) {
    //     return user.roles_access.is_manager && user.roles_access.is_owner;
    // }

    // static managerMaintainer(user) {
    //     return user.roles_access.is_manager && user.roles_access.is_maintainer;
    // }

    // static managerMember(user) {
    //     return user.roles_access.is_manager && user.roles_access.is_member;
    // }

    // static clientOwner(user) {
    //     return user.roles_access.is_client && user.roles_access.is_owner;
    // }

    // static clientMaintainer(user) {
    //     return user.roles_access.is_client && user.roles_access.is_maintainer;
    // }

    // static clientMember(user) {
    //     return user.roles_access.is_client && user.roles_access.is_member;
    // }

    // static employeeMaintainer(user) {
    //     return user.roles_access.is_employee && user.roles_access.is_maintainer;
    // }

    // static employeeMember(user) {
    //     return user.roles_access.is_employee && user.roles_access.is_member;
    // }
}
