<template>
    <div class="form-group">
        <div ref="dropdown" class="selector" @click="toggleDropdown">
            <div class="selected-option">
                {{ selectedCategory ? $t(`selected.${selectedCategory.name}`) : 'Select a category' }}
            </div>
            <div v-if="dropdownOpen" class="dropdown-list">
                <div
                    v-for="category in categories"
                    :key="category.name"
                    class="dropdown-item"
                    @click="selectCategory(category)"
                >
                    {{ $t(`selected.${category.name}`) }}
                </div>
            </div>
            <div class="selector-icons">
                <span v-show="selectedCategory" class="clear-select" @click.stop="clearCategorySelect">
                    <font-awesome-icon :icon="['fas', 'xmark']" />
                </span>
                <span class="icon icon-chevron-down at-select__arrow" :class="{ rotated: dropdownOpen }" />
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        props: {
            value: { type: String, deafult: '' },
        },
        data() {
            return {
                dropdownOpen: false,
                selectedCategory: { name: 'interval' },
                categories: [{ name: 'interval' }, { name: 'accounting' }, { name: 'task' }],
            };
        },
        created() {
            if (this.value.length) {
                this.selectedCategory = { name: this.value };
            }
        },
        methods: {
            toggleDropdown() {
                this.dropdownOpen = !this.dropdownOpen;
            },
            selectCategory(category) {
                this.selectedCategory = category;
                this.dropdownOpen = false;
                this.$emit('change', category.name);
            },
            clearCategorySelect() {
                this.selectedCategory = null;
                this.$emit('categoryCleared');
            },
            closeDropdown(event) {
                if (!this.$refs.dropdown.contains(event.target)) {
                    this.dropdownOpen = false;
                }
            },
        },
        mounted() {
            document.addEventListener('click', this.closeDropdown);
        },
        beforeDestroy() {
            document.removeEventListener('click', this.closeDropdown);
        },
    };
</script>

<style scoped lang="scss">
    @import '../../Tasks/styles/actions/New.scss';
</style>
